<template>

     <b-card  v-if="loading">  

      <b-row>

        <b-col
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: center;"
          >
            <h4 class="font-weight-bolder">
              Edición de perfil
            </h4>
            <hr class="mb-2">

          </b-col>

          <b-col
          cols="12"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >

          <b-skeleton width="50%"  height="20px"></b-skeleton>

          <b-skeleton width="100%"  height="30px"></b-skeleton>


        
        </b-col>

        <b-col
        cols="12"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >

          <b-skeleton width="50%"  height="20px"></b-skeleton>

          <b-skeleton width="100%"  height="30px"></b-skeleton>


        
        </b-col>

        <b-col
        cols="12"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >

          <b-skeleton width="50%"  height="20px"></b-skeleton>

          <b-skeleton width="100%"  height="30px"></b-skeleton>


        
        </b-col>


        <b-col
        cols="12"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >

          <b-skeleton width="50%"  height="20px"></b-skeleton>

          <b-skeleton width="100%"  height="30px"></b-skeleton>


        
        </b-col>


        <b-col
        cols="12"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >

          <b-skeleton width="50%"  height="20px"></b-skeleton>

          <b-skeleton width="100%"  height="30px"></b-skeleton>


        
        </b-col>


        <b-col
        cols="12"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >

          <b-skeleton width="50%"  height="20px"></b-skeleton>

          <b-skeleton width="100%"  height="30px"></b-skeleton>


        
        </b-col>

        <b-col
        cols="3"
            sm="3"
            md="3"
            lg="3"
            xl="3"
          > </b-col>


          <b-col
          cols="6"
            sm="6"
            md="6"
            lg="6"
            xl="6"
          > 

          <b-skeleton width="100%"  height="20px"></b-skeleton>

          <table width="100%">
            <tr>
              <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            </tr>
          </table>

        
        </b-col>

          <b-col
          cols="3"
            sm="3"
            md="3"
            lg="3"
            xl="3"
          >   </b-col>


        <b-col
        cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          > </b-col>


        <b-col
        cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >

          <b-skeleton width="100%"  height="30px"></b-skeleton>
        
        </b-col>


        <b-col
        cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          > </b-col>

      </b-row>


    </b-card>



      <b-card  v-else-if="code200">

 

      <b-form
        autocomplete="off"
        @submit.prevent="onSubmit"
      >

        <b-row>

          <b-col
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: center;"
          >
            <h4 class="font-weight-bolder">
              Edición de perfil
            </h4>
            <hr class="mb-2">

          </b-col>


    
          <b-col
            sm="12"
            md="12"
            lg="12"
            xl="12"
            v-if="hasDni == false"
          >

            <b-alert
              variant="primary"
              show
            >
              <div class="alert-body" style="text-align: center;">
                <span> El campo cédula puede ser editado sólo una sola vez.</span>
              </div>
            </b-alert>

          </b-col>

           <b-col
            v-if="hasNacionalidad"
            cols="6"
            sm="6"
            md="6"
            lg="3"
            xl="3"
            style="margin-bottom: 10px;"
          >

    <p class="textLabel">
              Nacionalidad:
            </p>

            <p class="textLabel2">
              {{ nacionalidad }}
            </p>

       

          </b-col>

          <b-col
            v-else
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="margin-bottom: 5px;margin-top: 10px;"

          >

          <b-row>

             <b-col
             cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="    padding: 0;"
           
          >

            <p class="textLabel">
              Seleccione su nacionalidad (*):
            </p>

          </b-col>

            <b-col
                   cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"

            style="padding:0px"
           
          >

          <b-row>
            <b-col
                              cols="6"
                                sm="6"
                                md="6"
                                lg="6"
                                xl="6"
                              >

                            <div class="inputGroup">



                            <input id="Ecuatoriano" name="radio" @click="onChange('Ecuatoriano')" :checked="checked"  value="Ecuatoriano" type="radio"/>
                            <label for="Ecuatoriano"> 

                              <span> <img  alt="Ecuador" style="margin-right: 5px;width: 25px;" src="/img/ecuador.svg">  </span>Ecuatoriano</label>
                            </div>

                            </b-col>

                            <b-col
                              cols="6"
                                sm="6"
                                md="6"
                                lg="6"
                                xl="6"
                                style="margin-bottom:10px"
                              >

                            <div class="inputGroup">



                            <input id="Extranjero" name="radio" @click="onChange('Extranjero')"  value="Extranjero" type="radio"/>
                            <label for="Extranjero"> 

                              <span> <img  alt="Extranjero" style="margin-right: 5px;width: 25px;" src="/img/world.svg">  </span>Extranjero</label>
                            </div>

                            </b-col>
          </b-row>

      

          </b-col>


          </b-row>


          </b-col>

          <b-col
            v-if="hasDni"
            cols="6"
            sm="6"
            md="6"
            lg="3"
            xl="3"
            style="margin-bottom: 10px;"
          >

            <p class="textLabel">
              Cédula/DNI:
            </p>

            <p class="textLabel2">
              {{ dni }}
            </p>

          </b-col>

          <b-col
            v-else
            sm="12"
            md="12"
            lg="6"
            xl="6"
            style="margin-bottom: 10px;"
          >

            <p class="textLabel">
              Cédula (*):
            </p>

            <b-form-input
              v-if="isEcuatoriano"
              id="dni"
              v-model="dni"
              placeholder="Ingrese su cédula"
              required
              @keypress="NumbersOnly"
              type="tel"
              :readonly="activarCampo"
    
            />


             <b-form-input
             v-else
              id="dni"
              v-model="dni"
              placeholder="Ingrese su DNI"
              required
              :readonly="activarCampo"
              type="text"
          
            />

          </b-col>

          <b-col
            sm="12"
            md="12"
            lg="6"
            xl="6"
            style="margin-bottom: 10px;"
          >

            <p class="textLabel">
              Nombres y apellidos:
            </p>

            <p v-if="hasDni" class="textLabel2">
              {{ displayName }}
            </p>

              <b-form-input
              v-else
              id="displayName"
              v-model="displayName"
              placeholder="Ingrese su apellido y nombre"
              required
              type="text"
              :readonly="activarCampo"
              @keypress="isLetter"
            />

          </b-col>



        

          <b-col
          cols="6"
            sm="6"
            md="6"
            lg="6"
            xl="6"
            style="margin-bottom: 10px;"
          >
            <p class="textLabel">
              Género (*):
            </p>

            <b-form-select
              id="sexo"
              v-model="sexo"
              :disabled="activarCampo"
              required
              :options="optionsSexo"
            />

          </b-col>

          <b-col
          cols="6"
            sm="12"
            md="12"
            lg="6"
            xl="6"
            style="margin-bottom: 10px;"
          >
            <p class="textLabel">
              Estado civil (*):
            </p>

            <b-form-select
              id="estadocivil"
              v-model="estadocivil"
              :disabled="activarCampo"
              required
              :options="optionsEstadoCivil"
            />

          </b-col>

         

          <b-col
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >

            <p
              class="textLabel"
              style="text-align: center;margin-bottom: 10px;"
            >
              Ingrese su código PIN (*): <feather-icon
                icon="HelpCircleIcon"
                size="16"
                  v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                title="Código PIN de seguridad"
                style="margin-right: 5px;cursor:pointer;"
                /> </p>

            <PincodeInput
              v-model="pin"
              placeholder="-"
              :length="6"
              :secure="true"
              required
              :autofocus="false"
            />

          </b-col>

          <b-col
            sm="12"
            md="12"
            lg="12"
            xl="12"
            align="center"
          >

            <b-button
              id="btn_profile"
              ref="btn_profile"
              type="submit"
              variant="primary"
              :disabled="isActiveProfile"
            >

              Actualizar mis datos

            </b-button>

          </b-col>

        </b-row>

      </b-form>
       </b-card>

      <b-card  v-else align="center">
  <feather-icon
             icon="FrownIcon"
             size="50"
             />
      <p class="errorTexto">
        Ocurrió un error al cargar los datos
      </p>

      <b-button
        id="btn_refresh"
        ref="btn_refresh"
        variant="primary"
        @click="refreshGeneral"
      >

        Intentar de nuevo

      </b-button>

        </b-card>

</template>

<script>
import {
  BButton, BForm,BFormTextarea,BSkeleton, BFormRadioGroup, BFormInput, BRow, BAlert, VBPopover, BCol, BFormSelect, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

  import Vue from 'vue'
import PincodeInput from 'vue-pincode-input'

export default {
  components: {
    BButton,
    BSkeleton,
    BFormTextarea,
    PincodeInput,
    BAlert,
    BFormSelect,
    BFormRadioGroup,
    BForm,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: ['userId', 'tokenAuth'],
  data() {
    return {
      maxChar: 250,
      checked: true,
      isActiveProfile: false,
      hasDni: false,
      activarCampo: false,
      pin: '',
      isValidNumber: false,
      displayName: '',
      nacionalidad:"Ecuatoriano",
      hasNacionalidad:false,
      isEcuatoriano:true,
      estadocivil: '',
      sexo: '',
      dni: '',
      dniRespaldo: '',
      loading: true,
      code200: false,
      pinProfile: '',
      optionsSexo: [
        { value: '', text: 'Seleccione una opción', disabled: true },
        { value: '1', text: 'Masculino' },
        { value: '2', text: 'Femenino' },
        { value: '3', text: 'Otro' },

      ],
      optionsEstadoCivil: [
        { value: '', text: 'Seleccione una opción', disabled: true },
        { value: 'Soltero/a', text: 'Soltero/a' },
        { value: 'Divorciado/a', text: 'Divorciado/a' },
        { value: 'Casado/a', text: 'Casado/a' },
        { value: 'Viuda/o', text: 'Viuda/o' },
        { value: 'Otro', text: 'Otro' },

      ],

    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {



    const dataEncripted = this.$encryptBackoffice.encrypt(this.userId)

    this.$https.post('/profile/getProfile/', { tokenAuth: this.tokenAuth, userId: dataEncripted }).then(response => {
      if (response.data.code == 200) {
        this.loading = false

        const { datos } = response.data

        this.displayName = datos.displayName.trim();



        this.nacionalidad=datos.nacionalidad;


          if (this.nacionalidad == '') {
          this.hasNacionalidad = false;
           this.nacionalidad = "Ecuatoriano";
        } else {
          this.hasNacionalidad = true
       
        }


         this.sexo = datos.sexo


 

        this.estadocivil = datos.estadocivil



        this.dni = datos.dni

        if (datos.dni == '') {
          this.hasDni = false
        } else {
          this.hasDni = true
        }

        this.code200 = true
      } else {
        this.$toast.error(response.data.message, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })

        if (response.data.code == 401) {
          
          localStorage.removeItem('userData')



          
          

           

          
          

          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        } else {
          this.code200 = false
        }
      }
    }).catch(error => {

     
      this.$toast.error("Ocurrió un error inesperado", {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
    })
  },
  methods: {

    isNumberBlur(){


        if(this.dni === null || this.dni === ""){

          this.dni="";


          }else{


            if(isNaN(this.dni)){

              this.$toast.error("El campo DNI solo permite valores numéricos", {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })
                this.dni="";

            }



          }



        },

    onChange(event) {
       
        this.dni="";
  
        this.nacionalidad= event;
  
            if(event === "Ecuatoriano"){
              this.isEcuatoriano=true;
              
             
             

            }else{

               this.isEcuatoriano=false;

            }
          },

    checkDNI() {
      if (this.dni == '') {

      } else if (this.dni != this.dniRespaldo) {
        this.$swal({

          html:
                              '<img style="margin-left:10px; width:30px " src="/img/loading.svg" alt="Loading" /> Comprobando cédula, espere',
          showCloseButton: false,
          showCancelButton: false,
          focusConfirm: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          position: 'center',
        })

        this.dni =this.dni.trim();



      const dataEncripted = this.$encryptBackoffice.encrypt(this.dni)


        this.$https.post('/profile/consultaDatos/', { cedula: dataEncripted }).then(response => {


          this.$swal.close()
          

      
          if (response.data.code == 200) {

        
            this.dniRespaldo = this.dni

            this.displayName = response.data.dataGeneral.nombres.trim()


            const select = document.querySelector('#sexo')

            if (response.data.dataGeneral.sexo != '1' && response.data.dataGeneral.sexo != '2') {
              select.value = '3'
              this.sexo = '3'
            } else {
              select.value = response.data.dataGeneral.sexo
              this.sexo = response.data.dataGeneral.sexo
            }

            const select2 = document.querySelector('#estadocivil')

            if (response.data.dataGeneral.estadocivil != 'Divorciado/a' && response.data.dataGeneral.estadocivil != 'Casado/a' && response.data.dataGeneral.estadocivil != 'Viuda/o' && response.data.dataGeneral.estadocivil != 'Soltero/a') {
              select2.value = 'Otro'
              this.estadocivil = 'Otro'
            } else {
              select2.value = response.data.dataGeneral.estadocivil
              this.estadocivil = response.data.dataGeneral.estadocivil
            }
          } else {
            this.dni = ''

            this.$toast.error(response.data.message, {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
          }
        }).catch(error => {
          this.dni = ''

          this.$toast.error("Ocurrió un error inesperado", {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        })
      }
    },
    refreshGeneral() {
      this.$eventBus.$emit('reiniciarGeneral')
    },
    NumbersOnly(evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
     isLetter(e) {
      const char = String.fromCharCode(e.keyCode) // Get the character
      if (/^[a-zA-Z\s]*$/g.test(char)) return true // Match with regex
      e.preventDefault() // If not match, don't add to input text
    },

    onSubmit(event) {
      event.preventDefault();




      const userId_json = {
        userId: this.userId, pin: this.pin,nacionalidad: this.nacionalidad, dni: this.dni, displayName: this.displayName,  sexo: this.sexo, estadocivil: this.estadocivil
      }
      const user_string = JSON.stringify(userId_json)


      const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

      this.activarCampo = true
      this.isActiveProfile = true;
       this.isEcuatoriano=true;

      document.getElementById('btn_profile').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Actualizando perfil'

      this.$https.post('/profile/updateProfile/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
        this.isActiveProfile = false
        this.isEcuatoriano=false;

        document.getElementById('btn_profile').innerHTML = 'Actualizar mis datos'

        if (response.data.code == 200) {


           const datos_json = JSON.parse(this.$userGlobal);

          datos_json.displayName = this.displayName
          datos_json.dni = this.dni
          Vue.prototype.$userGlobal= JSON.stringify(datos_json);


          localStorage.setItem('userData', JSON.stringify(datos_json))
          this.refreshGeneral()
          this.$eventBus.$emit('reiniciarVerificacion')

              this.$toast.success(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          });



        } else {
          this.activarCampo = false

          this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

          if (response.data.code == 401) {
            
            localStorage.removeItem('userData')



            
            

           

            
            

            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          } else if (response.data.code == 502) {
            this.dni = ''
          } else if (response.data.code == 503) {
            this.pin = ''
          }
        }
      }).catch(error => {
        this.$toast.error("Ocurrió un error inesperado", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })

      

    
    },

  },
}
</script>
