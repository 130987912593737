<template>
    

    <b-card  v-if="loading">  

<b-row>

  <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              align="center"
            >
              <h4 class="font-weight-bolder">
                Actualización de cuenta bancaria
              </h4>
              <hr class="mb-2">

            </b-col>

    <b-col
          cols="3"
            sm="3"
            md="3"
            lg="3"
            xl="3"
          >
          </b-col>

    <b-col
          cols="6"
            sm="6"
            md="6"
            lg="6"
            xl="6"
          >

          <b-skeleton width="100%"  height="10px"></b-skeleton>
          <b-skeleton width="100%"  height="40px"></b-skeleton>


        
        </b-col>

        <b-col
          cols="3"
            sm="3"
            md="3"
            lg="3"
            xl="3"
          >
          </b-col>



    <b-col
          cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >

          <b-skeleton width="100%"  height="10px"></b-skeleton>
         




        
        </b-col>

        <b-col
        cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >

          </b-col>

          <b-col
            cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >

          </b-col>

          <b-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >

          <b-skeleton width="100%"  height="40px"></b-skeleton>


          </b-col>

          <b-col
            cols="6"
            sm="6"
            md="6"
            lg="6"
            xl="6"
          >

          <b-skeleton width="100%"  height="10px"></b-skeleton>
          <b-skeleton width="100%"  height="30px"></b-skeleton>


          </b-col>

          <b-col
            cols="6"
            sm="6"
            md="6"
            lg="6"
            xl="6"
          >

          <b-skeleton width="100%"  height="10px"></b-skeleton>
          <b-skeleton width="100%"  height="30px"></b-skeleton>


          </b-col>

          <b-col
        cols="3"
            sm="3"
            md="3"
            lg="3"
            xl="3"
          > </b-col>


          <b-col
          cols="6"
            sm="6"
            md="6"
            lg="6"
            xl="6"
          > 

          <b-skeleton width="100%"  height="20px"></b-skeleton>

          <table width="100%">
            <tr>
              <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            </tr>
          </table>

        
        </b-col>

          <b-col
          cols="3"
            sm="3"
            md="3"
            lg="3"
            xl="3"
          >   </b-col>


        <b-col
        cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          > </b-col>


        <b-col
        cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >

          <b-skeleton width="100%"  height="30px"></b-skeleton>
        
        </b-col>


        <b-col
        cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          > </b-col>

    </b-row>

    </b-card>


        <b-card  v-else-if="code200">

 

        <b-form
          autocomplete="off"
          @submit.prevent="onSubmit"
        >

          <b-row>

             <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              align="center"
            >
              <h4 class="font-weight-bolder">
                Actualización de cuenta bancaria
              </h4>
              <hr class="mb-2">

            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="3"
              xl="3"
              style="margin-bottom: 10px;"
            >
            </b-col>


            <b-col
              sm="12"
              md="12"
              lg="6"
              xl="6"
              style="margin-bottom: 10px;"
            >

              <p class="textLabel">
                Seleccione un país (*):
              </p>

              <b-form-select
                v-model="countryCode"
                required
                :options="optionsCountry"
                @change="onChange($event)"
              />

            </b-col>

            
            <b-col
              sm="12"
              md="12"
              lg="3"
              xl="3"
              style="margin-bottom: 10px;"
            >
            </b-col>

     

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-bottom: 10px;"
            >

              <p class="textLabel">
                Institución bancaria (*):
              </p>
              
              <v-select
                v-model="bank"
                placeholder="Seleccione un banco"
                :options="options"
                :required="!bankCode"
                label="Banco"
                :disabled="isSelectedCountry"
                @input="setSelected"
              >
                <template #search="{attributes, events}">
                  <input
                    class="vs__search"
                    :required="!bankCode"
                    v-bind="attributes"
                    v-on="events"
                  >
                </template>

              </v-select>

            </b-col>


            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-bottom: 10px;"
              v-if="isUS"
            >
            <b-row>

          

            <b-col
              sm="12"
              md="12"
              lg="4"
              xl="4"
              style="margin-bottom: 10px;"
            >

              <p class="textLabel">
                Tipo de cuenta bancaria (*):
              </p>

              <b-form-select
                v-model="type"
                :disabled="isSelectedCountry"
                required
                :options="optionsType"
              />

            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="4"
              xl="4"
              style="margin-bottom: 10px;"
            >

              <p class="textLabel">
                Nro. cuenta bancaria (*):
              </p>

              <b-form-input
                id="numberAccount"
                v-model="numberAccount"
                placeholder="Ingrese su cuenta bancaria"
                required
                @blur="isNumberBlur"
                    @keypress="NumbersOnly"
                     type="tel"
                :disabled="isSelectedCountry"

              />

            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="4"
              xl="4"
              style="margin-bottom: 10px;"
            >

              <p class="textLabel">
                ACH Routing Number (*):
              </p>

              <b-form-input
               
                v-model="ach"
                placeholder="Ingrese su ACH"
                required
                maxlength="20"
                @blur="isNumberACHBlur"
                    @keypress="NumbersOnly"
                     type="tel"
                :disabled="isSelectedCountry"
        
              />

            </b-col>
             </b-row>

            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-bottom: 10px;"
              v-else
            >

            <b-row>

              <b-col
              sm="12"
              md="12"
              lg="6"
              xl="6"
              style="margin-bottom: 10px;"
            >

              <p class="textLabel">
                Tipo de cuenta bancaria (*):
              </p>

              <b-form-select
                v-model="type"
                :disabled="isSelectedCountry"
                required
                :options="optionsType"
              />

            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="6"
              xl="6"
              style="margin-bottom: 10px;"
            >

              <p class="textLabel">
                Nro. cuenta bancaria (*):
              </p>

              <b-form-input
                id="numberAccount"
                v-model="numberAccount"
                placeholder="Ingrese su cuenta bancaria"
                required
                @blur="isNumberBlur"
                    @keypress="NumbersOnly"
                     type="tel"
                :disabled="isSelectedCountry"
    
              />

            </b-col>

        
              
            </b-row>

            </b-col>


            <b-col
            cols="1"
              sm="1"
              md="1"
              lg="1"
              xl="1"
              
            ></b-col>

            <b-col
            cols="10"
              sm="10"
              md="10"
              lg="10"
              xl="10"
              style="text-align: center; margin-bottom:15px"
            >
            <b-form-checkbox v-model="confirmar"  @change="onChangeCheck($event)" > <b>Confirmo que la cuenta bancaria registrada es de mi pertenencia</b>  </b-form-checkbox>
          </b-col>

          <b-col
            cols="1"
              sm="1"
              md="1"
              lg="1"
              xl="1"
              
            ></b-col>


              <b-col
              sm="12"
              md="12"
              lg="3"
              xl="3"
            ></b-col>

            <b-col
              sm="12"
              md="12"
              lg="6"
              xl="6"
            >

              <p
                class="textLabel"
                style="text-align: center;margin-bottom: 10px;"
              >
                Ingrese su código PIN (*): <feather-icon
                icon="HelpCircleIcon"
                size="16"
                  v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                title="Código PIN de seguridad"
                style="margin-right: 5px;cursor:pointer;"
                /> </p>

              <PincodeInput
                v-model="pin"
                required
                placeholder="-"
                :length="6"
                :autofocus="false"
                :secure="true"
                :readonly="isSelectedCountry"
              />

            </b-col>

               <b-col
              sm="12"
              md="12"
              lg="3"
              xl="3"
            ></b-col>

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              align="center"
            >

              <b-button
                id="btn_registrar"
                ref="btn_registrar"
                type="submit"
                variant="primary"
                :disabled="isCheckConfirmar"
              >

                Actualizar cuenta bancaria

              </b-button>

            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12"
              style="margin-top:10px"
              xl="12"
            >




            <b-alert
                        variant="primary"
                        show
                        style="margin-top:0px;"
                        >
                        <div class="alert-body">
                                
                     
                        <p style="font-size: 14px;    margin: 0;text-align: justify;">Los datos de la cuenta bancaria a registrarse deben corresponder con la información personal registrada en su perfil de EQCoop. </p>
                        <p style="font-size: 14px;    margin: 0;text-align: justify;">No se aceptan cuentas bancarias de terceras personas, caso contrario, sus solicitudes de retiro de dinero serán rechazadas. </p>

                        </div>
                        </b-alert>

         </b-col>


          </b-row>

        </b-form>

         </b-card>

  
         


       <b-card v-else-if="isProfileNotVerificated" align="center">

            <img

              alt="Usuario no verificado"
              src="/img/verifyAccount.svg"
              >

              <p style="text-align: center; font-size:14px; line-height: 25px;margin-top: 20px;">Para utilizar esta funcionalidad su cuenta debe estar verificada.</p>
              

        <b-button
          id="btn_refresh"
          ref="btn_refresh"
          variant="primary"
          :to='{ name: "perfil", hash: "#verificacion"}'
        >

          Verificar mi cuenta

        </b-button>

         </b-card>

         
       <b-card v-else align="center">

            <feather-icon
                icon="FrownIcon"
                size="50"
                />

            <p class="errorTexto">
              Ocurrió un error al cargar los datos
            </p>

            <b-button
              id="btn_refresh"
              ref="btn_refresh"
              variant="primary"
              @click="refreshBankAccount"
            >

              Intentar de nuevo

            </b-button>

            </b-card>




</template>

<script>
import {
  BButton, BForm,BFormCheckbox, BFormInput, VBPopover, BFormGroup,BAlert, BFormSelect, BSkeleton, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import PincodeInput from 'vue-pincode-input'

export default {
  components: {
    PincodeInput,
    vSelect,
    BFormCheckbox,
    BAlert,
    BFormSelect,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BSkeleton,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: ['userId', 'tokenAuth'],
  data() {
    return {
      code200: false,
      loading: true,
      isProfileNotVerificated:false,
      isCheckConfirmar:true,
      pin: '',
      bank: '',
      bankCode: '',
      confirmar:false,
      type: '',
      numberAccount: '',
      ach:"",
      isUS:false,
      optionsCountry: [
        { value: '', text: 'Seleccione una opción', disabled: true },
        { value: 'EC', text: 'Ecuador' },
        { value: 'VE', text: 'Venezuela' },
        { value: 'US', text: 'Estados Unidos' },
      ],

      optionsType: [
        { value: '', text: 'Seleccione una opción', disabled: true },
        { value: 'Ahorros', text: 'Ahorros' },
        { value: 'Corriente', text: 'Corriente' },

      ],
      options: [],
      banksVE: [],
      banksEc: [],
      banksUS: [],
      countryCode:"",
      isSelectedCountry:true,

    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {

    const dataEncripted = this.$encryptBackoffice.encrypt(this.userId)

    this.$https.post('/banks/checkHasBankAccount/', { tokenAuth: this.tokenAuth, userId: dataEncripted }).then(response => {
      this.loading = false
      if (response.data.code == 200) {

        const { hasAccount } = response.data

        if (hasAccount) {
          // debo setear los resultados
          this.countryCode= response.data.bankAccount.countryCode;

          this.ach=response.data.bankAccount.ach;

          this.options= response.data.banks;
          switch(this.countryCode) {
            case "EC":
            this.isUS=false;



            this.banksEc=response.data.banks;
        


            break;

            case "VE":
            this.isUS=false;
       

            this.banksVE=response.data.banks;

            break;

            case "US":
              this.isUS=true;

            this.banksUS=response.data.banks;

            break;

          }

          this.numberAccount = response.data.bankAccount.numberAccount
          this.type = response.data.bankAccount.type
          this.bankCode = response.data.bankAccount.bankCode
          this.bank = response.data.bankAccount.bank;
          this.isSelectedCountry=false;
        }else{
          this.isSelectedCountry=true;
          

        }

        this.code200 = true
      } else {


        if (response.data.code == 401) {

          this.$toast.error(response.data.message, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
        
          
          localStorage.removeItem('userData')


     
          
          

           

          
          

          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        } else {

          this.code200 = false

          if (response.data.code == 501) {
            this.isProfileNotVerificated=true;

          }else{

            this.isProfileNotVerificated=false;

          }
         
        }
      }
    }).catch(error => {
      this.$toast.error("Ocurrió un error inesperado", {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
    })
  },
  methods: {

    isNumberACHBlur(){

              
        if(this.ach === null || this.ach === ""){

        this.ach="";


        }else{


          if(isNaN(this.ach)){

            this.$toast.error("El campo ACH solo permite valores numéricos", {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })
              this.ach="";

          }



        }



    },
    isNumberBlur(){


if(this.numberAccount === null || this.numberAccount === ""){

  this.numberAccount="";


  }else{


    if(isNaN(this.numberAccount)){

      this.$toast.error("El campo de numero de cuenta solo permite valores numéricos", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
        this.numberAccount="";

    }



  }



},
NumbersOnly(evt) {
evt = (evt) || window.event
const charCode = (evt.which) ? evt.which : evt.keyCode
if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
evt.preventDefault()
} else {
return true
}
},
    onChangeCheck(opcion){

      if(opcion){
        this.isCheckConfirmar= false;

      }else{
        this.isCheckConfirmar= true;
      }

    },
    
    onChange(opcion){

      let consulta=false;
      this.isSelectedCountry= true;
      this.options=[];

      switch(opcion) {
            case "EC":
            this.isUS=false;


            if(Number(this.banksEc.length) === Number("0")){
              consulta=true;

            }else{
              consulta= false;
              this.options= this.banksEc;
              this.isSelectedCountry= false;

            }
        


            break;

            case "VE":
            this.isUS=false;

      

            if(Number(this.banksVE.length) === Number("0")){
              consulta=true;

            }else{
              consulta= false;
              this.options= this.banksVE;
              this.isSelectedCountry= false;

            }


            break;

            case "US":
            this.isUS=true;

            if(Number(this.banksUS.length) === Number("0")){
              consulta=true;

            }else{
              consulta= false;
              this.options= this.banksUS;
              this.isSelectedCountry= false;

            }

            break;

          }

     if(consulta){

      this.$swal({
            html:
            '<img style="margin-left:10px; width: 30px" src="/img/loading.svg" alt="Loading" /> Cargando bancos, espere',
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            position: 'center',
          })

      this.$https.post('/banks/listBanks/', { countryCode: opcion }).then(response => {

        this.$swal.close()

  

              if (response.data.code == 200) {

                this.options= response.data.banks;
                this.isSelectedCountry= false;

                switch(opcion) {
                      case "EC":
             
                      this.isUS=false;
                      this.banksEc= response.data.banks;
                  


                      break;

                      case "VE":
                
                      this.isUS=false;
                      this.banksVE= response.data.banks;

                


                      break;

                      case "US":
                      this.isUS=true;
                      this.banksUS= response.data.banks;


                      break;

                    }

              
              } else {
                this.$toast.error(response.data.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })


              }
              }).catch(error => {

              this.onChange(opcion);
              })

     } 
    

    },

    refreshBankAccount() {
      this.$eventBus.$emit('reiniciarBankAccount')
    },
    onSubmit(event) {
      event.preventDefault()

      const data_json = {
        userId: this.userId, pin: this.pin,countryCode:this.countryCode,ach: this.ach, numberAccount: this.numberAccount, bankCode: this.bankCode, type: this.type,
      }
      const data_string = JSON.stringify(data_json)



      const dataEncripted = this.$encryptBackoffice.encrypt(data_string);

      this.isSelectedCountry=true;
      this.isCheckConfirmar=true;

   

      document.getElementById('btn_registrar').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Actualizando'

      this.$https.post('/banks/registerAccountBank/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
        document.getElementById('btn_registrar').innerHTML = 'Actualizar'
        this.isSelectedCountry=false;
        this.isCheckConfirmar=true;
        this.confirmar=false;

        if (response.data.code == 200) {
          this.pin = ''
          this.$toast.success(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
          if(Number(response.data.eqpoints) > Number("0")){

            
              this.eqcoins(response.data.eqpoints, "asociar una cuenta bancaria a tu perfil", "");
       

              }

        } else {
          this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

          if (response.data.code == 401) {
            
            localStorage.removeItem('userData')



            
            

           

            
            

            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          } else if (response.data.code == 503) {
            this.pin = ''
          }else if(response.data.code == 501){
            this.$swal({
                title: 'Tu perfil debe estar verificado para realizar esta acción',
                text: `¿Deseas verificar tu cuenta ahora?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Verificar cuenta',
                cancelButtonText: 'No',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-secondary ml-1',
                },
                buttonsStyling: false,
              }).then(result => {
                if (result.value) {

                  this.$router.push({ name: 'perfil', hash: '#verificacion' })

                }
              })
          }
        }
      }).catch(error => {
        this.$toast.error("Ocurrió un error inesperado", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })
    },


    setSelected(value) {
      this.bankCode = value.Codigo
  
    },

  },
}
</script>

<style lang="scss">




</style>
