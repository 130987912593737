<template>


      <b-card>

    
<b-row>


<b-col cols="3" sm="3" md="3" lg="3" xl="3" style="text-align: right !important">

  <img alt="EQCoins" title="EQCoins" style="width: 60px;" src="/img/eqcoins.svg" />


</b-col>

<b-col cols="9" sm="9" md="9" lg="9" xl="9" style="display:flex" >
  <div style="margin: auto">
    <b-skeleton v-if="searchEQcoins" width="100%" height="20px" style="margin:0" ></b-skeleton>

<p v-else style="text-align:center; font-size:25px;     margin-bottom: 10px;line-height: 13px; font-weight: bold;">{{EQPoints}}</p>        




<p style="text-align:center; font-size:12px;     margin: 0;">Total de EQcoins obtenidos <feather-icon
          icon="HelpCircleIcon"
          size="16"
            v-b-popover.hover.bottom.html="'Los EQCoins son puntos acumulados que podrás canjearlo por premios.'"
          title="EQCoins"
          style="margin-right: 5px;cursor:pointer;"
          /> </p>
  </div>
     
    

    </b-col>

</b-row>



        </b-card>


</template>

<script>
import { BRow, BCol,BCard,  VBTooltip,VBPopover,BSkeleton } from 'bootstrap-vue'


export default {
  components: {
    BCard,
    BSkeleton,
    BRow,
    BCol,
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  props: ['userId','tokenAuth'],
  data() {
    return {
        searchEQcoins:true,
      EQPoints:0,
   

    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {

    this.getEQcoinsInsignias();

  },
  methods: {

    getEQcoinsInsignias(){

this.$https.post('/profile/getEQPoints/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {
    
   
  if (response.data.code == 200) {
    this.searchEQcoins = false

    this.EQPoints= response.data.EQPoints;

  } else {

    if (response.data.code == 401) {

        this.$toast.error(response.data.message, {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
      
      localStorage.removeItem('userData')




      
      

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    } else {
      this.getEQcoinsInsignias();
    }
  }
}).catch(error => {
     this.getEQcoinsInsignias();
})


}
  },
}
</script>

<style lang="scss">




</style>
