<template>
 

      <b-card
        v-if="loadingPin"
        align="center"
      >

      <b-row>

        <b-col
        cols="3"
            sm="3"
            md="3"
            lg="3"
            xl="3"
          ></b-col>

          <b-col
          cols="6"
            sm="6"
            md="6"
            lg="6"
            xl="6"
          >
              
          <b-skeleton width="100%"  height="20px"></b-skeleton>


        </b-col>

          <b-col
          cols="3"
            sm="3"
            md="3"
            lg="3"
            xl="3"
          ></b-col>

          <b-col
            sm="12"
            md="12"
            lg="12"
            xl="12"
          > <hr style="margin:5px"></b-col>

  <b-col cols="12" sm="6" md="6" lg="6" xl="6" style="margin-top: 15px;">

<b-skeleton width="100%"  height="40px"></b-skeleton>

<table width="100%">
            <tr>
              <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            </tr>
          </table>

</b-col>

<b-col cols="12" sm="6" md="6" lg="6" xl="6" style="margin-top: 15px;">

<b-skeleton width="100%"  height="40px"></b-skeleton>

<table width="100%">
            <tr>
              <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            </tr>
          </table>

</b-col>

<b-col
        cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          > </b-col>


        <b-col
        cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >

          <b-skeleton width="100%"  height="40px"></b-skeleton>
        
        </b-col>


        <b-col
        cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          > </b-col>

          </b-row>

    
      </b-card>



        <b-card v-else-if="code200">

       


          <b-form
           v-if="hasPinResponse"
            autocomplete="off"
            @submit.prevent="updatePin"
          >

            <b-row>

                    <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
                align="center"
              >
                <h4 class="font-weight-bolder">
                  Cambio de PIN de seguridad
                </h4>
                <hr class="mb-2">

              </b-col>

     

              <!-- old password -->
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
              >
                <p
                  class="textLabel"
                  style="text-align: center;"
                >
                  Antiguo PIN de seguridad (*):
                </p>

                <PincodeInput
                  v-model="passwordValueOld"
                  required
                  placeholder="-"
                  :length="6"
                  :autofocus="false"
                  :secure="true"
                />

              </b-col>
              <!--/ old password -->

              <!-- new password -->
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
              >

                <p
                  class="textLabel"
                  style="text-align: center;"
                >
                  Nuevo Pin de seguridad (*):
                </p>

                <PincodeInput
                  v-model="newPasswordValue"
                  required
                  placeholder="-"
                  :length="6"
                  :autofocus="false"
                  :secure="true"
                />

              </b-col>
              <!--/ new password -->

  

              <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
                align="center"
                style="margin-bottom:10px"
              >

                <b-button
                  id="btn_update_pin"
                  ref="btn_update_pin"
                  style="margin-top: 10px;"
                  type="submit"
                  variant="primary"
                  :disabled="isActivePin"
                  
                >

                  Actualizar Pin

                </b-button>

              </b-col>


     
            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="padding: 0;"
            >

            <hr>

            <p style="text-align: center;font-weight: bold;">¿Olvidaste tu código PIN?</p>

            <b-form
                  autocomplete="off"
                  @submit.prevent="onSubmitSearchPin"
                >
                <b-row>

              <b-col
              sm="12"
              md="12"
              lg="6"
              xl="6"
              align="center"
              style="margin-bottom:10px"
            >
          
            <p class="textLabel">
              Seleccione su pregunta de seguridad (*): <feather-icon
              icon="HelpCircleIcon"
              size="18"
                v-b-popover.hover.bottom.html="'Debe escoger la pregunta de seguridad que seleccionó al momento que registró su código PIN.'"
              title="Pregunta de seguridad"
              style="margin-right: 5px;cursor:pointer;"
              /> 
            </p>

            <b-form-select
              v-model="question"
              required
              :options="optionsType"
              :disabled="isActive"
              @change="onChange($event)"
            />
          </b-col>

            <b-col
              sm="12"
              md="12"
              lg="6"
              xl="6"
              align="center"
              style="margin-bottom:10px"
            >
          
            
            <p class="textLabel">
              Escriba su respuesta (*):
              </p>

            <b-form-input
            v-model="respuesta"
            placeholder="Ingrese su respuesta"
            required
            type="text"
            :readonly="isActive"
          />

          </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              align="center"
            >

              <b-button
                id="btn_search_pin"
                ref="btn_search_pin"
                style="margin-top: 10px;"
                type="submit"
                variant="primary"
                :disabled="isActive"
                
              >

                Recuperar PIN

              </b-button>

            </b-col>

                </b-row>

                </b-form>


            </b-col>


            

            </b-row>
          </b-form>


         



          <b-form
           v-else
            autocomplete="off"
            @submit.prevent="onSubmit"
          >

            <b-row>

              <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
                align="center"
              >
                <h4 class="font-weight-bolder">
                  Registro de PIN de seguridad
                </h4>
                <hr class="mb-2">

              </b-col>

              <b-col
                sm="12"
                md="12"
                lg="3"
                xl="3"
              ></b-col>

              <b-col
                sm="12"
                md="12"
                lg="6"
                xl="6"
              >
     
                <p class="textLabel" style="text-align: center;">
                  Ingrese un código de 6 dígitos (*): <feather-icon
                icon="HelpCircleIcon"
                size="18"
                  v-b-popover.hover.bottom.html="'El PIN es un código de seis dígitos que te brindará una protección adicional en todas las transacciones que realices.'"
                title="Código PIN de seguridad"
                style="margin-right: 5px;cursor:pointer;"
                /> </p>

                <PincodeInput
                  v-model="pin"
                  required
                  placeholder="-"
                  :length="6"
                  :autofocus="false"
                  :secure="true"
                  :readonly="isActive"
                />

              </b-col>

              <b-col
                sm="12"
                md="12"
                lg="3"
                xl="3"
              ></b-col>
            


              <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-bottom: 10px;"
            >

              <p class="textLabel">
                Pregunta de seguridad (*): <feather-icon
                icon="HelpCircleIcon"
                size="18"
                  v-b-popover.hover.bottom.html="'La pregunta de seguridad es una medida de protección adicional que se te solicitará responder cuando desees recuperar tu código PIN en el caso de que lo hayas olvidado.'"
                title="Pregunta de seguridad"
                style="margin-right: 5px;cursor:pointer;"
                /> 
              </p>

              <b-form-select
                v-model="question"
                required
                :options="optionsType"
                :disabled="isActive"
                @change="onChange($event)"
              />

            </b-col>

                      
            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              v-if="ifRespuesta"
              style="margin-bottom: 15px;"
              
              >

              <p class="textLabel">
                Escriba su respuesta (*):
                </p>

              <b-form-input
              v-model="respuesta"
              placeholder="Ingrese su respuesta"
              required
              type="text"
              :readonly="isActive"
            />

            
            </b-col>

              <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
                align="center"
              >

                <b-button
                  id="btn_registrar_pin"
                  ref="btn_registrar_pin"
                  type="submit"
                  variant="primary"
                  :disabled="isActive"
                >

                  Registrar

                </b-button>

              </b-col>

            </b-row>

          </b-form>

 

        </b-card>

 

       <b-card align="center"  v-else>

       <feather-icon
             icon="FrownIcon"
             size="50"
             />
        <p class="errorTexto">
          Ocurrió un error al cargar los datos
        </p>

        <b-button
          id="btn_refresh"
          ref="btn_refresh"
          variant="primary"
          @click="refreshDobleAu"
        >

          Intentar de nuevo

        </b-button>

         </b-card>


</template>

<script>
import {
  BButton, BForm,BFormSelect, BFormInput, VBPopover, BFormGroup, BSkeleton, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Vue from 'vue'
import PincodeInput from 'vue-pincode-input'

export default {
  components: {
    PincodeInput,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BSkeleton,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: ['userId','tokenAuth'],
  data() {
    return {
      hasPinResponse: false,
      code200: false,
      isActive: false,
      isActivePin: false,
      loadingPin: true,
      ifRespuesta:false,
      respuesta:"",
      pin: '',
      question:"",
      passwordValueOld: '',
      questionOption:"",
      newPasswordValue: '',
      optionsType: [
        { value: '', text: 'Seleccione una pregunta', disabled: true },
        { value: 'question1', text: '¿En qué ciudad conoció a su cónyuge o pareja?' },
        { value: 'question2', text: '¿En qué ciudad se conocieron sus padres?' },
        { value: 'question3', text: '¿En qué ciudad tuvo su primer trabajo?' },
        { value: 'question4', text: '¿Cuál es el apellido de su profesor favorito del instituto?' },
        { value: 'question5', text: '¿Cuál era el nombre y apellido de su mejor amigo de la infancia?' },
        { value: 'question6', text: '¿Cuál era el nombre de su primera mascota?' },
        { value: 'question7', text: '¿Cuál es la persona más famosa que ha conocido?' }
      ],
    }
  },
  computed: {

    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

  },
  watch: {

    /*
    pin(newValue) {
      if (newValue.length == 6) {
        this.isActive = false
      } else {
        this.isActive = true
      }
    },

    */
  },
  mounted() {


    const dataEncripted = this.$encryptBackoffice.encrypt(this.userId)

    this.$https.post('/profile/checkHasPin/', { tokenAuth: this.tokenAuth, userId: dataEncripted }).then(response => {
      if (response.data.code == 200) {
        this.loadingPin = false

        const  hasPin  = response.data.hasPin;

        if (hasPin) {
          this.hasPinResponse = true
        } else {
          this.hasPinResponse = false
        }

        this.code200 = true
      } else {
        this.$toast.error(response.data.message, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })

        if (response.data.code == 401) {
          
          localStorage.removeItem('userData')



          
          

           

          
          

          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        } else {
          this.code200 = false
        }
      }
    }).catch(error => {
      this.$toast.error("Ocurrió un error inesperado", {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
    })
  },
  methods: {

    onChange(opcion){

      this.questionOption= opcion;
      this.ifRespuesta=true;



    },

    refreshDobleAu() {
      this.$eventBus.$emit('reiniciarDobleAu')
    },
    updatePin(event) {
      event.preventDefault()

      if (this.passwordValueOld.length == 6) {
        if (this.newPasswordValue.length == 6) {
          this.$swal({
            title: '¿Estas seguro?',
            text: `El antiguo PIN ingresado es: ${this.passwordValueOld} y el nuevo Pin ingresado es: ${this.newPasswordValue}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, actualizar',
            cancelButtonText: 'Cancelar',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-secondary ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              const data_json = { userId: this.userId, pinOld: this.passwordValueOld, newPin: this.newPasswordValue }
              const data_string = JSON.stringify(data_json)

              const dataEncripted = this.$encryptBackoffice.encrypt(data_string)

              this.isActivePin = true

              document.getElementById('btn_update_pin').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Actualizando PIN'

              this.$https.post('/profile/updatePin/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
                document.getElementById('btn_update_pin').innerHTML = 'Actualizar'
                this.isActivePin = false

                if (response.data.code == 200) {
                  this.passwordValueOld = ''
                  this.newPasswordValue = ''

                     this.$toast.success(response.data.message, {
                      position: 'top-right',
                      timeout: 3010,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.4,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: 'button',
                      icon: true,
                      rtl: false,
                    })


                } else {
                  this.passwordValueOld = ''
                  this.newPasswordValue = ''

                  this.$toast.error(response.data.message, {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                  })

                  if (response.data.code == 401) {
                    
                    localStorage.removeItem('userData')



                    
                    

           

                    
                    

                    // Redirect to login page
                    this.$router.push({ name: 'auth-login' })
                  }
                }
              }).catch(error => {
                this.$toast.error("Ocurrió un error inesperado", {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })
              })
            }
          })
        } else {
          this.$toast.error('El nuevo PIN debe ser de 6 digitos', {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        }
      } else {
        this.$toast.error('El antiguo PIN debe ser de 6 digitos', {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      }
    },
    onSubmitSearchPin(event){


      event.preventDefault()

      const userId_json = { userId: this.userId,questionOption: this.questionOption, respuesta: this.respuesta  }
          const user_string = JSON.stringify(userId_json)

          const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

          this.isActive = true

          document.getElementById('btn_search_pin').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Buscando'

          this.$https.post('/profile/searchPin/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
            this.isActive = false
            document.getElementById('btn_search_pin').innerHTML = 'Recuperar PIN';
       

            if (response.data.code == 200) {

              this.respuesta="";

                   this.$swal({
                    html: '<p style="margin-bottom:25px; font-size: 16px;text-align:center">Su código PIN es: </p><p style="margin-bottom:5px; font-weight:bold; font-size: 35px;text-align:center">'+response.data.pinSecurity+' </p>',
                    showCancelButton: false,
                    confirmButtonText: 'Entendido',
                    focusConfirm: true,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  });

              
       

                    
                     
            } else {
              this.$toast.error(response.data.message, {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })

              if (response.data.code == 401) {
                
                localStorage.removeItem('userData')



                
                

           

                
                

                // Redirect to login page
                this.$router.push({ name: 'auth-login' })
              }
            }
          }).catch(error => {
            this.$toast.error("Ocurrió un error inesperado", {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
          })

    },

    onSubmit(event) {
      event.preventDefault()
      this.$swal({
        title: '¿Estas seguro?',
        text: `El código PIN ingresado es: ${this.pin}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, registrar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const userId_json = { userId: this.userId, pin: this.pin,questionOption: this.questionOption, respuesta: this.respuesta  }
          const user_string = JSON.stringify(userId_json)

          const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

          this.isActive = true

          document.getElementById('btn_registrar_pin').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Registrando PIN'

          this.$https.post('/profile/registerPin/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
            this.isActive = false
            document.getElementById('btn_registrar_pin').innerHTML = 'Registrar'

            if (response.data.code == 200) {
              this.hasPinResponse = true

              
              const datos_json = JSON.parse(this.$userGlobal);
    
 
                  
    datos_json.hasPin =true
  
    Vue.prototype.$userGlobal= JSON.stringify(datos_json);


  localStorage.setItem('userData', JSON.stringify(datos_json))
  

              this.respuesta="";
       

                      this.$toast.success(response.data.message, {
                      position: 'top-right',
                      timeout: 3010,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.4,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: 'button',
                      icon: true,
                      rtl: false,
                    });

                
                    this.$eventBus.$emit('reiniciarNotificacionesNavBar');

                     this.$eventBus.$emit('reiniciarBot');



                    
                     
            } else {
              this.$toast.error(response.data.message, {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })

              if (response.data.code == 401) {
                
                localStorage.removeItem('userData')



                
                

           

                
                

                // Redirect to login page
                this.$router.push({ name: 'auth-login' })
              }
            }
          }).catch(error => {
            this.$toast.error("Ocurrió un error inesperado", {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
          })
        }
      })
    },

  },
}
</script>

<style lang="scss">




</style>
